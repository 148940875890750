import React from 'react';
import HeaderOther from '../components/HeaderOther';
import '../styles/pages/home.css'


const Professors = () => {

  return (
    <section>
        <HeaderOther />
    </section>
  );
};

export default Professors;